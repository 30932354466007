<template>
  <section class="page">
    <section class="hero has-hero-image is-between-small-and-medium">
      <div id="hero_image" :style="'background-image: url(' + directory.hero_uri + ')'" />
      <router-link v-if="logoUri" id="logo" to="/" aria-label="Home">
        <img class="logo" :src="logoUri" alt="Directory Hero">
      </router-link>
      <div class="hero-body directory-home">
        <div class="has-text-centered columns is-multiline is-vcentered">
          <div class="column is-12">
            <h1 class="title is-size-1 has-text-white">
              {{ directory.name }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <div v-if="directory.description" id="stat-panel" class="level">
      <div class="level-item">
        <div v-html="directory.description" />
      </div>
    </div>
    <div v-if="hasPrimaryFilter" class="section projects pb-0">
      <FeaturedPrimaryFilterList />
    </div>
    <div class="section projects">
      <h1 class="title">
        All Categories
      </h1>
      <PrimaryFilterList />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FeaturedPrimaryFilterList from '@/components/InstantSearch/FeaturedPrimaryFilterList.vue';
import PrimaryFilterList from '@/components/InstantSearch/PrimaryFilterList.vue';

export default {
  computed: {
    hasPrimaryFilter() {
      return (this.directory.primary_filter?.featured?.length ?? 0) > 0;
    },
    ...mapState({
      logoUri: (state) => state.logo_uri,
      instanceName: (state) => state.name,
    }),
    ...mapGetters({
      directory: 'directory/current',
    }),
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.directory?.primary_filter?.field) {
      this.$router.push({
        name: 'directory',
        params: { directory: this.directory.slug },
      });
    }
  },
  components: {
    FeaturedPrimaryFilterList,
    PrimaryFilterList,
  },
  metaInfo() {
    const { instanceName } = this;
    const directoryName = this.directory.name;
    const description = this.directory.meta_description;

    const title = `${directoryName} - ${instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: description,
      },
    ];
    return { title, meta };
  },
};
</script>
